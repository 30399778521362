import React from 'react'
import { graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
// import Card from '../components/Card'

// import CustomCategoryFilterEn from '../components/CustomCategoryFilterEn'
// import SingleContentOnProjects from '../components/SingleContentOnProjects'
// import posed, { PoseGroup } from 'react-pose'
import FullWidthProject from '../components/FullWidthProjectComponent'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import Favicon from '../favicon-32x32.png'
import ogMeta from '../img/an_og_projects.jpg'
//
// const CatWrap = posed.div({
//   enter: {
//     y: 0,
//     opacity: 1,
//     delay: 300,
//     transition: {
//       y: { type: 'spring', stiffness: 100, damping: 100 },
//       default: { duration: 300 },
//     },
//   },
//   exit: {
//     y: 50,
//     opacity: 0,
//     transition: { duration: 150 },
//   },
// })

const LocationOne = ({ allProjects }) => (
  <>
    {allProjects.map(({ node }) => (
      <div
        key={node.id}
        className="preanimate actv elementOfGallery animated fadeIn  delay-1s "
      >
        <div className="atviated leChild">
          <FullWidthProject
            title={node.title}
            content={node.content}
            oneLiner={node.projectOneliner.projectoneliner}
            image={node.featuredImage.node.localFile.childImageSharp.original.src}
            url={node.uri}
          />
        </div>
      </div>
    ))}
  </>
)

const LocationTwo = ({ cleanFour }) => (
  <div>
    {cleanFour.map((element, key) => (
      <div
        key={element.id}
        className="preanimate actv elementOfGallery animated fadeIn  delay-1s "
      >
        <div className="atviated leChild">
          <FullWidthProject
            title={element.title}
            content={element.content}
            oneLiner={element.projectOneliner.projectoneliner}
            image={element.featuredImage.node.localFile.childImageSharp.original.src}
            url={element.uri}
          />
        </div>
      </div>
    ))}
  </div>
)

const LocationThree = ({ cleanFive }) => (
  <div>
    {cleanFive.map((element, key) => (
      <div
        key={element.id}
        className="preanimate actv elementOfGallery animated fadeIn  delay-1s "
      >
        <div className="atviated leChild">
          {/* three */}
          {/* element.featured_media.localFile.childImageSharp.gatsbyImageData.originalImg */}
          <FullWidthProject
            title={element.title}
            content={element.content}
            oneLiner={element.projectOneliner.projectoneliner}
            image={element.featuredImage.node.localFile.childImageSharp.original.src}
            url={element.uri}
          />
        </div>
      </div>
    ))}
  </div>
)

class ProjectsPage extends React.Component {
  constructor() {
    super()
    this.state = {
      activeLocation: 'one',
      isVisible: false,
    }
    this.showLocationOne = this.showLocationOne.bind(this)
    this.showLocationTwo = this.showLocationTwo.bind(this)
    this.showLocationThree = this.showLocationThree.bind(this)
  }

  showLocationOne() {
    this.setState({
      activeLocation: 'one',
    })
  }

  showLocationTwo() {
    this.setState({
      activeLocation: 'two',
    })
  }

  showLocationThree() {
    this.setState({
      activeLocation: 'three',
    })
  }

  handleChange = (categoryId) => {
    this.setState({ leActiveCategory: categoryId })
    // this.setState({ classOfActive: 'activeSolo' })
  }

  render() {
    const { data } = this.props

    const { edges: allProjects } = data.allWpProjectPostType

    const onlyFour = allProjects.map(({ node }) => {
      // console.log('node.categories[node]')
      // console.log(node.categories.nodes[0])
      if (node.categories.nodes[0].termTaxonomyId === 4) return node
    }, null)

    const onlyFive = allProjects.map(({ node }) => {
      if (node.categories.nodes[0].termTaxonomyId === 5) return node
    }, null)

    const cleanFour = onlyFour.filter(
      (elementFour) => elementFour !== undefined
    )

    const cleanFive = onlyFive.filter(
      (elementFive) => elementFive !== undefined
    )

    return (
      <div>
        {/* <Helmet title="" /> */}
        <Helmet>
          <meta charSet="utf-8" />
          <title>Projects | Interior Design Studio</title>
          <link rel="canonical" href="https://ananenadovic.com/projects/" />

          <meta name="description" content="Our Projects" />
          <meta name="robots" content="index" />
          <link rel="icon" type="image/png" sizes="32x32" href={Favicon} />
          <meta
            property="og:title"
            content="Projects | Interior Design Studio"
          />

          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="https://ananenadovic.com/projects/"
          />
          <meta property="og:image" content={ogMeta} />

          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:title"
            content="Projects | Interior Design Studio"
          />
          <meta name="twitter:description" content="Our Projects" />
          <meta name="twitter:image" content={ogMeta} />
        </Helmet>
        <Navbar />
        <div className="test">
          <div className="allProjectsWrap">
            <div
              id="btn-group-iso"
              className="button-group filters-button-group"
            >
              <div className="category-holder">
                <h1>Projects</h1>
                <div
                  className={
                    this.state.activeLocation === 'one'
                      ? 'is_active catButton  active '
                      : ' catButton '
                  }
                  role="button"
                  onClick={this.showLocationOne}
                >
                  All
                </div>

                <div
                  className={
                    this.state.activeLocation === 'two'
                      ? 'is_active catButton  active '
                      : ' catButton '
                  }
                  onClick={this.showLocationTwo}
                >
                  Commercial
                </div>

                <div
                  className={
                    this.state.activeLocation === 'three'
                      ? 'is_active catButton  active '
                      : ' catButton '
                  }
                  onClick={this.showLocationThree}
                >
                  Residential
                </div>
              </div>
              <div className="section gallerySingleSection">
                <div className=" gallerySingleGrid">
                  {(() => {
                    if (this.state.activeLocation === 'one') {
                      return <LocationOne allProjects={allProjects} />
                    }
                    if (this.state.activeLocation === 'two') {
                      return <LocationTwo cleanFour={cleanFour} />
                    }
                    if (this.state.activeLocation === 'three') {
                      return <LocationThree cleanFive={cleanFive} />
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
          <div className="stickTheFooter">
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

ProjectsPage.propTypes = {
  data: PropTypes.shape({
    allWpProjectPostType: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  cleanFour: PropTypes.array,
}

export default ProjectsPage

export const pageQuery = graphql`
  query ProjectsById {
    allWpProjectPostType(
      sort: { fields: date, order: DESC }
      filter: { locale: { locale: { eq: "en_US" } } }
    ) {
      edges {
        node {
          id
          title
          slug
          content
          uri
          categories {
            nodes {
              slug
              name
              count
              id
              termTaxonomyId
            }
          }
          projectOneliner {
            projectdatetext
            projectoneliner
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  original {
                    src
                    height
                    width
                  }
                }
              }
              mediaItemUrl
              slug
              sourceUrl
              mediaDetails {
                file
                width
                height
              }
            }
          }
        }
      }
    }
  }
`
